// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-en-js": () => import("./../src/pages/blog.en.js" /* webpackChunkName: "component---src-pages-blog-en-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-ru-js": () => import("./../src/pages/blog.ru.js" /* webpackChunkName: "component---src-pages-blog-ru-js" */),
  "component---src-pages-hello-js": () => import("./../src/pages/hello.js" /* webpackChunkName: "component---src-pages-hello-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-ru-js": () => import("./../src/pages/index.ru.js" /* webpackChunkName: "component---src-pages-index-ru-js" */),
  "component---src-pages-netlifyneedsthis-js": () => import("./../src/pages/netlifyneedsthis.js" /* webpackChunkName: "component---src-pages-netlifyneedsthis-js" */),
  "component---src-pages-privacy-en-js": () => import("./../src/pages/privacy.en.js" /* webpackChunkName: "component---src-pages-privacy-en-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacy-ru-js": () => import("./../src/pages/privacy.ru.js" /* webpackChunkName: "component---src-pages-privacy-ru-js" */),
  "component---src-pages-terms-en-js": () => import("./../src/pages/terms.en.js" /* webpackChunkName: "component---src-pages-terms-en-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-terms-ru-js": () => import("./../src/pages/terms.ru.js" /* webpackChunkName: "component---src-pages-terms-ru-js" */)
}

